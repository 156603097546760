import { Filters, Params, statusesMap, TkFilters } from './types'
import { OPERATORS } from './constants'
import format from 'date-fns/format'

const { IS_SET, IS_NOT_SET } = OPERATORS

export const createQueryParams = (filter: Filters | null, key: string, withLabels?: boolean) => {
  if (filter) {
    const { operator, values } = filter
    if (values?.length) {
      const valuesString = values
        .map(val => {
          if (withLabels && !key.includes('Date')) {
            if (key === 'id') {
              return `${encodeURIComponent(val.label)};${val.value}`
            }
            return `${val.value};${encodeURIComponent(val.label)}`
          }

          return key.includes('Date')
            ? format(new Date(val.value), 'YYYY-MM-DD')
            : key === 'id'
            ? encodeURIComponent(val.label)
            : val.value
        })
        .join(',')
      const operatorString = String(operator?.value).toLowerCase() ?? 'is_set'
      if (key === 'classification')
        return valuesString ? `${operatorString}:${valuesString}` : `${operatorString}:`
      return valuesString ? `${operatorString}:${valuesString}` : `${operatorString}`
    } else {
      if (operator?.value === IS_SET) {
        return 'is_set:'
      } else if (operator?.value === IS_NOT_SET) {
        return 'is_not_set:'
      }
    }
  }
}

export const getParamsFromQueryString = (queryString: URLSearchParams, initialParams: Params) => {
  const paramKeys = ['page_number', 'page_size', 'columnKey', 'isDesc', 'search', 'category']
  const params: Params = { ...initialParams }

  for (const key of paramKeys) {
    const value = queryString.get(key)
    if (value && !['columnKey', 'isDesc'].includes(key)) {
      if (key === 'page_number') params.page = Number(value)
      else if (key === 'page_size') params.pageSize = Number(value)
      else if (key === 'search') params.search = value
      else if (key === 'category') params.category = value
    } else if (key === 'columnKey' && value) {
      params.ordering.columnKey = value
    } else if (key === 'isDesc' && value) {
      params.ordering.isDesc = value === 'true'
    }
  }
  return params
}

export const createFiltersObjectFromQueryString = (queryString: URLSearchParams) => {
  const filterKey = [
    'timekeeper_id',
    'tk_name',
    'classification',
    'currency',
    'requested_rate',
    'rack_rate',
    'status',
    'approved',
    'rejected',
    'pending'
  ]
  const filters: TkFilters = {
    tk_name: null,
    timekeeper_id: null,
    classification: null,
    currency: null,
    requested_rate: null,
    rack_rate: null,
    status: null,
    approved: null,
    rejected: null,
    pending: null
  }

  const parseQueryString = (queryString: string, k: string) => {
    const result: {
      operator: string | null
      values: { value: number | string; label: string }[]
    } = {
      operator: '',
      values: []
    }

    const [operator, pairsString] = queryString.split(':')

    const pairs = pairsString?.split(',') ?? []

    for (const pair of pairs) {
      const [valueNumber, label] = pair.split(';')
      if (k === 'status') {
        result.values.push({ value: valueNumber, label: statusesMap[valueNumber] })
      } else if (k.includes('Date')) {
        result.values.push({ value: new Date(valueNumber).toString(), label: 'Date' })
      } else {
        result.values.push({
          value: valueNumber,
          label: label ? decodeURIComponent(label) : valueNumber || 'Blank'
        })
      }
    }

    return { ...result, operator: operator ? operator.toUpperCase() : operator }
  }

  const operatorsList = [
    { value: 'IS_SET', label: 'Is set' },
    { value: 'IS_NOT_SET', label: 'Is not set' },
    { value: 'IS', label: 'Is' },
    { value: 'IS_NOT', label: 'Is not' },
    { value: 'IS_BEFORE', label: 'Is before' },
    { value: 'IS_AFTER', label: 'Is after' },
    { value: 'IS_BETWEEN', label: 'Is between' }
  ]

  for (const key of filterKey) {
    const value = queryString.get(key)

    if (value) {
      const k = key as keyof typeof filters

      if (value.toUpperCase() === 'IS_SET') {
        filters[k] = { operator: { value: 'IS_SET', label: 'Is set' }, values: null }
      } else if (value.toUpperCase() === 'IS_NOT_SET') {
        filters[k] = { operator: { value: 'IS_NOT_SET', label: 'Is not set' }, values: null }
      } else {
        const filter = parseQueryString(value, k)

        filters[k] = {
          operator: operatorsList.find(o => o.value === filter.operator) ?? null,
          values: filter.values
        }
      }
    }
  }
  return filters
}

export const amountPattern = (value: string, pushFloating?: any) => {
  let newValue = value.replace(/[^\d\.]/g, '')
  if (pushFloating && newValue !== '') {
    let [whole, floating] = newValue.split('.')
    const maxDecimalPlaces = 2
    if (floating && floating.length > maxDecimalPlaces) {
      whole += floating.substring(0, floating.length - maxDecimalPlaces)
      floating = floating.substring(floating.length - maxDecimalPlaces)
      newValue = whole + '.' + floating
    }
  }
  return newValue
}

export const decideCustomizableColumnsFieldName = (
  view: string,
  selectedTab: string,
  showBatchDetails: boolean
): string | undefined => {
  if (view === 'list') {
    return 'allTimekeepersList'
  }

  if (view === 'rates') {
    if (selectedTab === 'all') {
      return 'allRatesList'
    }

    if (showBatchDetails) {
      if (selectedTab === 'pending') {
        return 'pendingBatchDetailsList'
      }

      if (selectedTab === 'completed') {
        return 'completedBatchDetailsList'
      }
    }
  }

  return undefined
}
