import {
  AllRatesAPI,
  CompletedBatchesAPI,
  PendingBatchesAPI,
  TimekeeperListAPI,
  TimekeeperProfileDetailsAPI,
  TimekeeperRow
} from 'timekeepers/types'
import shortid from 'shortid'
import { formatDate } from './helpers'

type TimekeeperAPI = PendingBatchesAPI | CompletedBatchesAPI | AllRatesAPI | TimekeeperListAPI

export const serializeTimekeepers = (
  rows: TimekeeperAPI[],
  isRatesView: boolean,
  selectedTab: string,
  isBatchDetailsView: boolean
): TimekeeperRow[] =>
  rows.map(row => {
    //@ts-expect-error
    const baseRow: Partial<TimekeeperRow> = {
      ...row,
      id:
        'id' in row ? row.id : (row as PendingBatchesAPI | CompletedBatchesAPI).timekeeper_file_id,
      ...('diversity' in row
        ? { diversity: `${(row as AllRatesAPI | TimekeeperListAPI).diversity}/5 filled` }
        : {}),
      disableRowSelection: 'is_rate_used' in row ? Boolean(row.is_rate_used) : false
    }

    if (!isRatesView) {
      Object.assign(baseRow, {
        classification: (row as TimekeeperListAPI).classification,
        timekeeper: `${(row as TimekeeperListAPI).first_name} ${
          (row as TimekeeperListAPI).last_name
        }`,
        timekeeper_row_id: (row as TimekeeperListAPI).id
      })
    }

    if (isRatesView && selectedTab !== 'all') {
      Object.assign(baseRow, {
        full_name:
          (row as PendingBatchesAPI | CompletedBatchesAPI).full_name === '-' ||
          !(row as PendingBatchesAPI | CompletedBatchesAPI).full_name
            ? ''
            : (row as PendingBatchesAPI | CompletedBatchesAPI).full_name
      })
    }

    if (isRatesView && selectedTab === 'completed' && !isBatchDetailsView) {
      Object.assign(baseRow, {
        approver_list: (row as CompletedBatchesAPI).approver_list
          .filter(approver => approver !== ' ')
          .map(approver => ({
            label: approver as string,
            id: shortid.generate()
          }))
      })
    }

    if ((isRatesView && selectedTab === 'all') || isBatchDetailsView) {
      Object.assign(baseRow, {
        timekeeper_row_id: (row as AllRatesAPI).timekeeper.id,
        classification: (row as AllRatesAPI).timekeeper.classification,
        status: (row as AllRatesAPI).status === 'None' ? 'pending' : (row as AllRatesAPI).status,
        timekeeper: `${(row as AllRatesAPI).timekeeper.first_name} ${
          (row as AllRatesAPI).timekeeper.last_name
        }`,
        timekeeper_id: (row as AllRatesAPI).timekeeper.timekeeper_id,
        vendor_name: (row as AllRatesAPI).timekeeper.vendor_name,
        vendor_id: (row as AllRatesAPI).timekeeper.vendor_id,
        location: (row as AllRatesAPI).timekeeper.location,
        email: (row as AllRatesAPI).timekeeper.email,
        practice_group: (row as AllRatesAPI).timekeeper.practice_group,
        country: (row as AllRatesAPI).timekeeper.country,
        bar_year: (row as AllRatesAPI).timekeeper.bar_year,
        created_date: (row as AllRatesAPI).timekeeper.created_date,
        race: (row as AllRatesAPI).timekeeper.race,
        disability: (row as AllRatesAPI).timekeeper.disability,
        gender: (row as AllRatesAPI).timekeeper.gender,
        lgbtq: (row as AllRatesAPI).timekeeper.lgbtq,
        veteran_status: (row as AllRatesAPI).timekeeper.veteran_status
      })
    }

    return baseRow as TimekeeperRow
  })

export const serializeTimekeeperProfileDetails = (
  row: TimekeeperRow,
  profileDetails: TimekeeperProfileDetailsAPI
) => {
  return {
    ...profileDetails,
    pendingRates: profileDetails.pendingRates.map(rate => ({
      ...rate,
      status: rate.status === 'None' ? 'pending' : rate.status,
      type: rate.type || 'Standard'
    })),
    isNew: row.is_new_rate || row.is_new_tk,
    effective: row.effective ? formatDate(row.effective) : '---',
    generalInfo: {
      classification: row.classification || '---',
      location: row.location || '---',
      email: row.email || '---',
      practiceGroup: row.practice_group || '---',
      country: row.country || '---',
      barYear: row.bar_year || '---'
    },
    diversityInfo: {
      race: row.race,
      disability: row.disability,
      gender: row.gender,
      lgbtq: row.lgbtq,
      veteran_status: row.veteran_status
    },
    timekeeperMeta: {
      name: row.timekeeper,
      id: row.timekeeper_id,
      created: row.created_date
    }
  }
}
