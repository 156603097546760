import { memo } from 'react'
import s from './Filters.scss'
import cn from 'classnames'
import { Filter } from './Filter'
import { FilterOption, Filters as FiltersType, statusesMap, TkFilters } from '../types'
import { OPERATORS } from '../constants'
import isEqual from 'lodash/isEqual'
import { CheckboxList, DropdownPopper, Input } from 'simple-core-ui'
import { amountPattern } from 'timekeepers/utils'
import * as yup from 'yup'

const { IS, IS_NOT } = OPERATORS

const rateSchema = yup.tuple([
  yup
    .string()
    .required()
    .min(0)
    .matches(/^\d+(\.{0,1}\d{0,2})$/, 'Only 2 values allowed after decimal'),
  yup
    .string()
    .optional()
    .min(0)
    .matches(/^\d+(\.{0,1}\d{0,2})$/, 'Only 2 values allowed after decimal')
    .test('is-less-than-start', (value, context) => {
      if (!value) return true
      const start = context.parent[0]
      return Number(start) < Number(value)
    })
])

const rateCountSchema = yup.tuple([
  yup
    .string()
    .required()
    .min(0)
    .matches(/^\d+$/, 'Only Numeric values allowed'),
  yup
    .string()
    .optional()
    .min(0)
    .matches(/^\d+$/, 'Numeric values allowed')
    .test('is-less-than-start', (value, context) => {
      if (!value) return true
      const start = context.parent[0]
      return Number(start) < Number(value)
    })
])

const numericOperatorList = [
  {
    label: 'Equals',
    value: 'IS'
  },
  {
    label: 'Is greater than',
    value: 'IS_AFTER'
  },
  {
    label: 'Is less than',
    value: 'IS_BEFORE'
  },
  {
    label: 'Is between',
    value: 'IS_BETWEEN'
  }
]

interface Props {
  className?: string
  tkFilters: TkFilters
  setTkFilters: (filters: TkFilters) => void
  clearFilters: () => void
  baseUrl: string
  view: string
  selectedTab: string
  showBatchDetails: boolean
}
interface InjectedProps {
  setFilters: (values: FilterOption[]) => void
  filters: FiltersType | null
}

const Filters = memo(
  ({ className, tkFilters, setTkFilters, clearFilters, baseUrl, view }: Props) => {
    const rateValidation = (value: FiltersType | null) => {
      if (!value) return true
      const { operator, values } = value
      // return true and let the filter default validation take care of it
      if (!operator || !values) return true
      if (operator.value === 'IS_BETWEEN' && values?.length < 2) {
        return false
      }
      let result = false
      try {
        result = rateSchema.isValidSync([values[0].value, values[1]?.value])
      } catch (ex) {
        return false
      }
      return result
    }

    const rateCountValidation = (value: FiltersType | null) => {
      if (!value) return true
      const { operator, values } = value
      // return true and let the filter default validation take care of it
      if (!operator || !values) return true
      if (operator.value === 'IS_BETWEEN' && values?.length < 2) {
        return false
      }
      let result = false
      try {
        result = rateCountSchema.isValidSync([values[0].value, values[1]?.value])
      } catch (ex) {
        return false
      }
      return result
    }

    return (
      <div className={cn(s.filters, className)}>
        <Filter
          label="ID"
          value={tkFilters.timekeeper_id}
          onConfirm={(value, cb) => {
            setTkFilters({ ...tkFilters, timekeeper_id: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper value={filters?.values ?? []} placeholder="Select id(s)" label="id">
              <CheckboxList
                url={`${baseUrl}/timekeeper/?field_name=timekeeper_id`}
                value={filters?.values ?? []}
                isPaginated
                firstPageIndex={1}
                onConfirm={setFilters}
                serialiser={(data: { options: string[] }) => {
                  return data.options.map(timekeeper_id => ({
                    value: timekeeper_id,
                    label: timekeeper_id
                  }))
                }}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Timekeeper Name"
          value={tkFilters.tk_name}
          onConfirm={(value, cb) => {
            setTkFilters({ ...tkFilters, tk_name: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select name(s)"
              label="timekeeper"
            >
              <CheckboxList
                url={`${baseUrl}/timekeeper/?field_name=full_name`}
                value={filters?.values ?? []}
                onConfirm={setFilters}
                isPaginated
                firstPageIndex={1}
                serialiser={(data: { options: string[] }) => {
                  return data.options.map(full_name => ({
                    value: full_name,
                    label: full_name || 'None'
                  }))
                }}
              />
            </DropdownPopper>
          )}
        </Filter>
        <Filter
          label="Classification"
          value={tkFilters.classification}
          onConfirm={(value, cb) => {
            setTkFilters({ ...tkFilters, classification: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is not',
              value: IS_NOT
            }
          ]}
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select classification(s)"
              label="classification"
            >
              <CheckboxList
                url={`${baseUrl}/timekeeper/?field_name=classification`}
                value={filters?.values ?? []}
                onConfirm={setFilters}
                isPaginated
                firstPageIndex={1}
                serialiser={(data: { options: string[] }) => {
                  return data.options.map(classification => ({
                    value: classification,
                    label: classification || 'Blank'
                  }))
                }}
              />
            </DropdownPopper>
          )}
        </Filter>
        {view == 'rates' && (
          <>
            <Filter
              label="Currency"
              value={tkFilters.currency}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, currency: value })
                cb?.()
              }}
              operatorsList={[
                {
                  label: 'Is',
                  value: IS
                },
                {
                  label: 'Is not',
                  value: IS_NOT
                }
              ]}
            >
              {({ filters, setFilters }: InjectedProps) => (
                <DropdownPopper
                  value={filters?.values ?? []}
                  placeholder="Select currency"
                  label="currency"
                >
                  <CheckboxList
                    url={`${baseUrl}/rate/?field_name=currency`}
                    value={filters?.values ?? []}
                    onConfirm={setFilters}
                    isPaginated
                    firstPageIndex={1}
                    serialiser={(data: { options: string[] }) => {
                      return data.options.map(currency => ({
                        value: currency,
                        label: currency
                      }))
                    }}
                  />
                </DropdownPopper>
              )}
            </Filter>
            <Filter
              label="Billed Rate"
              value={tkFilters.requested_rate || null}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, requested_rate: value })
                cb?.()
              }}
              validation={rateValidation}
              operatorsList={numericOperatorList}
              customLabel={() => {
                const filter = tkFilters.requested_rate
                if (!filter) return 'Billed Rate'
                const { operator, values } = filter
                switch (operator?.value) {
                  case 'IS':
                    return `Billed Rate equals ${values?.[0].value}`
                  case 'IS_BETWEEN':
                    return `Billed Rate is between ${values?.[0].value} - ${values?.[1].value}`
                  case 'IS_AFTER':
                    return `Billed Rate is greater than ${values?.[0].value}`
                  case 'IS_BEFORE':
                    return `Billed Rate is less than ${values?.[0].value}`
                  default:
                    return 'Billed Rate'
                }
              }}
            >
              {({ filters, setFilters }) => {
                const [from, to] = filters?.values || []
                const isBetween = filters?.operator?.value === 'IS_BETWEEN'

                return (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Input
                      type="number"
                      className={s.input}
                      placeholder={isBetween ? 'From rate' : 'Amount'}
                      text={from?.value?.toString() || ''}
                      onChangeCb={e => {
                        const aux = [...(filters?.values || [])]
                        aux[0] = {
                          value: amountPattern(e.target.value),
                          label: amountPattern(e.target.value)
                        }
                        setFilters(aux)
                      }}
                    />
                    {isBetween && (
                      <Input
                        type="number"
                        className={s.input}
                        placeholder={isBetween ? 'To rate' : 'Amount'}
                        text={to?.value?.toString() || ''}
                        onChangeCb={e => {
                          const aux = [...(filters?.values || [])]
                          aux[1] = {
                            value: amountPattern(e.target.value),
                            label: amountPattern(e.target.value)
                          }
                          setFilters(aux)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Filter>
            <Filter
              label="Rack Rate"
              value={tkFilters.rack_rate || null}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, rack_rate: value })
                cb?.()
              }}
              validation={rateValidation}
              operatorsList={numericOperatorList}
              customLabel={() => {
                const filter = tkFilters.rack_rate
                if (!filter) return 'Rack Rate'
                const { operator, values } = filter
                switch (operator?.value) {
                  case 'IS':
                    return `Rack Rate equals ${values?.[0].value}`
                  case 'IS_BETWEEN':
                    return `Rack Rate is between ${values?.[0].value} - ${values?.[1].value}`
                  case 'IS_AFTER':
                    return `Rack Rate is greater than ${values?.[0].value}`
                  case 'IS_BEFORE':
                    return `Rack Rate is less than ${values?.[0].value}`
                  default:
                    return 'Rack Rate'
                }
              }}
            >
              {({ filters, setFilters }) => {
                const [from, to] = filters?.values || []
                const isBetween = filters?.operator?.value === 'IS_BETWEEN'

                return (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Input
                      type="number"
                      className={s.input}
                      placeholder={isBetween ? 'From rate' : 'Amount'}
                      text={from?.value.toString() || ''}
                      onChangeCb={e => {
                        const aux = [...(filters?.values || [])]
                        aux[0] = {
                          value: amountPattern(e.target.value),
                          label: amountPattern(e.target.value)
                        }
                        setFilters(aux)
                      }}
                    />
                    {isBetween && (
                      <Input
                        type="number"
                        className={s.input}
                        placeholder={isBetween ? 'To rate' : 'Amount'}
                        text={to?.value.toString() || ''}
                        onChangeCb={e => {
                          const aux = [...(filters?.values || [])]
                          aux[1] = {
                            value: amountPattern(e.target.value),
                            label: amountPattern(e.target.value)
                          }
                          setFilters(aux)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Filter>
            <Filter
              label="Status"
              value={tkFilters.status}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, status: value })
                cb?.()
              }}
              operatorsList={[
                {
                  label: 'Is',
                  value: IS
                },
                {
                  label: 'Is not',
                  value: IS_NOT
                }
              ]}
            >
              {({ filters, setFilters }: InjectedProps) => (
                <DropdownPopper
                  value={filters?.values ?? []}
                  placeholder="Select status(es)"
                  label="status"
                >
                  <CheckboxList
                    url={`${baseUrl}/rate/?field_name=status`}
                    value={filters?.values ?? []}
                    onConfirm={setFilters}
                    isPaginated
                    firstPageIndex={1}
                    serialiser={(data: { options: string[] }) => {
                      return data.options
                        .filter(o => o)
                        .map(status => ({
                          value: status,
                          label: statusesMap[status]
                        }))
                    }}
                  />
                </DropdownPopper>
              )}
            </Filter>
          </>
        )}
        {view == 'list' && (
          <>
            <Filter
              label="Approved Rate"
              value={tkFilters.approved || null}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, approved: value })
                cb?.()
              }}
              validation={rateCountValidation}
              operatorsList={numericOperatorList}
              customLabel={() => {
                const filter = tkFilters.approved
                if (!filter) return 'Approved Rate'
                const { operator, values } = filter
                switch (operator?.value) {
                  case 'IS':
                    return `Approved Rate equals ${values?.[0].value}`
                  case 'IS_BETWEEN':
                    return `Approved Rate is between ${values?.[0].value} - ${values?.[1].value}`
                  case 'IS_AFTER':
                    return `Approved Rate is greater than ${values?.[0].value}`
                  case 'IS_BEFORE':
                    return `Approved Rate is less than ${values?.[0].value}`
                  default:
                    return 'Approved Rate'
                }
              }}
            >
              {({ filters, setFilters }) => {
                const [from, to] = filters?.values || []
                const isBetween = filters?.operator?.value === 'IS_BETWEEN'

                return (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <input
                      type="number"
                      className={s.input}
                      placeholder={isBetween ? 'From rate' : 'Amount'}
                      value={from?.value || ''}
                      onChange={e => {
                        const aux = [...(filters?.values || [])]
                        aux[0] = {
                          value: amountPattern(e.target.value),
                          label: amountPattern(e.target.value)
                        }
                        setFilters(aux)
                      }}
                    />
                    {isBetween && (
                      <input
                        type="number"
                        className={s.input}
                        placeholder={isBetween ? 'To rate' : 'Amount'}
                        value={to?.value || ''}
                        onChange={e => {
                          const aux = [...(filters?.values || [])]
                          aux[1] = {
                            value: amountPattern(e.target.value),
                            label: amountPattern(e.target.value)
                          }
                          setFilters(aux)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Filter>
            <Filter
              label="Rejected Rate"
              value={tkFilters.rejected || null}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, rejected: value })
                cb?.()
              }}
              validation={rateCountValidation}
              operatorsList={numericOperatorList}
              customLabel={() => {
                const filter = tkFilters.rejected
                if (!filter) return 'Rejected Rate'
                const { operator, values } = filter
                switch (operator?.value) {
                  case 'IS':
                    return `Rejected Rate equals ${values?.[0].value}`
                  case 'IS_BETWEEN':
                    return `Rejected Rate is between ${values?.[0].value} - ${values?.[1].value}`
                  case 'IS_AFTER':
                    return `Rejected Rate is greater than ${values?.[0].value}`
                  case 'IS_BEFORE':
                    return `Rejected Rate is less than ${values?.[0].value}`
                  default:
                    return 'Rejected Rate'
                }
              }}
            >
              {({ filters, setFilters }) => {
                const [from, to] = filters?.values || []
                const isBetween = filters?.operator?.value === 'IS_BETWEEN'

                return (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <input
                      type="number"
                      className={s.input}
                      placeholder={isBetween ? 'From rate' : 'Amount'}
                      value={from?.value || ''}
                      onChange={e => {
                        const aux = [...(filters?.values || [])]
                        aux[0] = {
                          value: amountPattern(e.target.value),
                          label: amountPattern(e.target.value)
                        }
                        setFilters(aux)
                      }}
                    />
                    {isBetween && (
                      <Input
                        type="number"
                        className={s.input}
                        placeholder={isBetween ? 'To rate' : 'Amount'}
                        text={to?.value?.toString() || ''}
                        onChangeCb={e => {
                          const aux = [...(filters?.values || [])]
                          aux[1] = {
                            value: amountPattern(e.target.value),
                            label: amountPattern(e.target.value)
                          }
                          setFilters(aux)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Filter>
            <Filter
              label="Pending Rate"
              value={tkFilters.pending || null}
              onConfirm={(value, cb) => {
                setTkFilters({ ...tkFilters, pending: value })
                cb?.()
              }}
              validation={rateCountValidation}
              operatorsList={numericOperatorList}
              customLabel={() => {
                const filter = tkFilters.pending
                if (!filter) return 'Pending Rate'
                const { operator, values } = filter
                switch (operator?.value) {
                  case 'IS':
                    return `Pending Rate equals ${values?.[0].value}`
                  case 'IS_BETWEEN':
                    return `Pending Rate is between ${values?.[0].value} - ${values?.[1].value}`
                  case 'IS_AFTER':
                    return `Pending Rate is greater than ${values?.[0].value}`
                  case 'IS_BEFORE':
                    return `Pending Rate is less than ${values?.[0].value}`
                  default:
                    return 'Pending Rate'
                }
              }}
            >
              {({ filters, setFilters }) => {
                const [from, to] = filters?.values || []
                const isBetween = filters?.operator?.value === 'IS_BETWEEN'

                return (
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Input
                      type="number"
                      className={s.input}
                      placeholder={isBetween ? 'From rate' : 'Amount'}
                      text={from?.value?.toString() || ''}
                      onChangeCb={e => {
                        const aux = [...(filters?.values || [])]
                        aux[0] = {
                          value: amountPattern(e.target.value),
                          label: amountPattern(e.target.value)
                        }
                        setFilters(aux)
                      }}
                    />
                    {isBetween && (
                      <Input
                        type="number"
                        className={s.input}
                        placeholder={isBetween ? 'To rate' : 'Amount'}
                        text={to?.value?.toString() || ''}
                        onChangeCb={e => {
                          const aux = [...(filters?.values || [])]
                          aux[1] = {
                            value: amountPattern(e.target.value),
                            label: amountPattern(e.target.value)
                          }
                          setFilters(aux)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Filter>
          </>
        )}
        <div className={s.reset} onClick={clearFilters}>
          Reset
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.tkFilters, nextProps.tkFilters) &&
      isEqual(prevProps.view, nextProps.view) &&
      isEqual(prevProps.selectedTab, nextProps.selectedTab) &&
      isEqual(prevProps.showBatchDetails, nextProps.showBatchDetails)
    )
  }
)

export default Filters
